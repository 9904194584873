<template>
    <div class="navbarmobileFix">
        <!-- <hr /> -->
        <div class="d-none d-lg-block">
            <nav class="three-nav">
                <ul class="three-nav navigation-list-item-blk" :class="{ 'show-navigation-list': seen }">
                    <li class="three-nav" @click="receiveConsultation()">
                        <div>
                            <img src="~@/assets/images1/doctor-hospital.png" style="width: 40px;">
                        </div>
                    </li>
                    <li class="three-nav" @click="getCase()">
                        <div>
                            <img src="~@/assets/images/one-one.png" style="width: 40px;">
                        </div>
                    </li>
                    <li class="three-nav" @click="offerConsultation()">
                        <div>
                            <img src="~@/assets/images/meet-icon.png" style="width: 40px;">
                        </div>
                    </li>
                    <li class="three-nav" @click="accessClinical()">
                        <div>
                            <img src="~@/assets/img/medical-knowledge.png" style="width: 40px;">
                        </div>
                    </li>
                    <!-- <li class="three-nav" @click="postConsultation()">
                    <div>
                        <img src="~@/assets/images/group.png" style="width: 40px;">
                    </div>
                </li> -->
                </ul>
            </nav>
        </div>
        <section class="banner-text img">
            <div class="container text-center text-align">
                <!-- <div class="d-md-none d-lg-block"> -->
                <div class="joinPatientTextHeading">
                    <h1 class="">Join patient groups</h1>
                </div>

                <!-- FOR DESKTOP  -->
                <div class="row d-none d-lg-block">
                    <div class="col-12 banner-sub-content joinPatientText">
                        <h3>Enhance your identity among the patient community.</h3>

                        <h3 class="mt-4 mb-0">Share your wisdom and knowledge. Answer patients'</h3>
                        <h3>queries. Guide users in their healthcare journey.</h3>

                        <h3 class="mt-4">There are no charges.</h3>
                    </div>
                </div>
                <!-- FOR MOBILE  -->
                <div class="row d-block d-lg-none">
                    <div class="col-12 banner-sub-content joinPatientText">
                        <h3 class="mt-4 mb-0">Enhance your identity among </h3>
                        <h3>the patient community.</h3>

                        <h3 class="mt-4 mb-0">Share your wisdom and</h3>
                        <h3 class="mb-0">knowledge.</h3>

                        <h3 class="mt-4 mb-0">Answer patients'</h3>
                        <h3 class="mb-0">queries. Guide users in their</h3>
                        <h3> healthcare journey.</h3>

                        <h3 class="mt-4 ">There are no charges.</h3>
                    
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </section>


        <section class="banner-text mt-3">
            <div class="container text-center text-align" style="background:#ffffff;">
                <!-- FOR DESKTOP  -->
                <div class="selectPatientHeading d-none d-lg-block">
                    <h2 class="mb-0">Select a disorder type to view </h2>
                    <h2>the related patient groups</h2>
                </div>
                <!-- FOR MOBILE  -->
                <div class="selectPatientHeading d-block d-lg-none">
                    <h2 class="mb-0">Select a disorder type to </h2>
                    <h2 class="mb-0">view the related patient</h2>
                    <h2>groups</h2>
                </div>
                <div class="mt-4">
                    <select class="form-control JoinPatient" name="select" id="select" v-model="selected"
                        @change="getPatintGroup($event)">
                        <option value="1">Select disorder</option>
                        <!-- <option value='all'>All</option> -->
                        <option v-for="index in allTopics" :key="index">{{ index.name }}</option>
                    </select>
                </div>
                <div v-if="filteredSpecilities.length">
                <div class=" container row box-items-space">
                    <div class="col-opt-box" v-for="index in filteredSpecilities" :key="index" @click="signupgeneral(index)">
                        <div class="patientgroupCard mt-3 color-bg" >
                            <div class="btn-text-bg">
                                <!-- <div class="box-text-bg" >{{ index.shortname }}
                                </div> -->
                                <div class="eyes-text">
                                    <p>{{ index.group_name }}</p>
                                </div>
                            </div>
                        </div>
                            <div>
                                <button class="btn btn-action-background" >Join
                                    group</button>
                            </div>
                    </div>
                </div>
            </div>
            <div v-else-if="selected !=='1' && !filteredSpecilities.length" class="mt-5">
                <h4 style="margin-top: 5px; margin-left: 10px;color:rgb(239, 63, 63)">No groups currently available</h4>
            </div>
            </div>
        </section>
    </div>
    <MobileNavbar></MobileNavbar>
</template>
<script>
import { defineComponent } from "vue";
import MobileNavbar from "../common-mobile-footer.vue"
import axios from "axios"
export default defineComponent({
    components: {
        MobileNavbar
    },
    el: '#hide',
    data() {
        return {
            seen: true,
            currentPath: "",
            allTopics: [],
            selected: '1',
            objSpeclities:{},
            filteredSpecilities:[]
        };
    },
    created: function () {
        this.getAllTopics();
        this.currentPath = this.$route.path.split('/')[2]
    },
    mounted(){
        this.getAllTopics() ;
    },
    methods: {
        signupgeneral(data) {
           const patientGroupPayload ={
            patientGroup:JSON.stringify(data),
            routeName : 'joinpatient',
            card:'doctor',
            DoctorPatientGroupID:JSON.stringify(1)
           }
            this.$router.push({ path: '/signup', query: patientGroupPayload });
        },
        receiveConsultation() {
            window.location.href = "/doctor/consultationrequests"; 
        },
        getCase() {
            window.location.href = "/doctor/communities"; 

        },
        offerConsultation() {
            window.location.href = "/doctor/offerconsultation"; 
        },
        accessClinical() {
            window.location.href = "/doctor/clinicalresources"; 
        },
        postConsultation() {
            window.location.href = "/presign/postconsultation"; 
        },
        async getAllTopics() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        const response = await axios.get(`${BASE_API_URL}/topics`);

        if (response?.data) {
            // Filter out the object with id: 24
            this.allTopics = response.data.filter(topic => topic.id !== 24);
        }
    },
        getPatintGroup(event){
        const topics= this.allTopics.filter(item => item.name == event.target.value);
        topics.map((item)=>{
        this.objSpeclities={
            check :item.check,
            id:item.id,
            name:item.name
        }
        })
          if(event.target.value != 1){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
        .get(`${BASE_API_URL}/patientgroup/specialities?disorder_id=${this.objSpeclities.id}`)
        .then((response) => {
        this.filteredSpecilities=[];
        if (response?.data) {
                response.data.map((patientGroup)=>{
            if(patientGroup.status == true){
                this.filteredSpecilities.push(patientGroup)
        }
        })
        }
    })
    }else if(event.target.value== 1){
        this.objSpeclities =false
        this.filteredSpecilities=[];
            }
        },
    },
    name: "HomePageB",
    props: {},
});    
</script>
<style>
select.form-control.JoinPatient {
    width: 400px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-bottom: 5px;

}

.joinPatientTextHeading h1 {
    text-align: center;
    margin-top: 20px;
    color: #000000;
    font-weight: bold;
}


.patitentgroupsText {
    font-size: 22px;
}

.selectPatientHeading h2 {
    color: #000;
    text-align: center;
}

.selectPatientHeading {
    color: #000;
    text-align: center;
    margin-top: 3rem;
}

.col-12.div-align {
    text-align: -webkit-center;
    width: 65%;
    margin: auto;
}

.navigation-list-item-blk {
    transition: 0.3s;
    /* transform: translateX(-100px); */
    transform: translateX(75px);
}

.show-navigation-list {
    transform: translateX(0px);
}



nav.navbar.navbar-expand-lg.navbar-light {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
}



.banner-text.img {
    margin-top: 4.5rem;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/cards-banner7.png");
    background-size: 3500px 593px;
    /* transform: rotate(90deg);  */
    padding: 30px 0px 50px 0px;

}

.banner-sub-content {
    margin-top: 20px;
}

button.presign.btn.btn-blue-color.text-white {
    float: right;
    width: 40%;
    /* width: auto; */
    font-weight: bold;
    font-size: large;
    /* margin: 10px -60px 10px 0px; */
}

.rxix-menu li.nav-item a.nav-link {
    color: #00979e;
}

button.btn.btn-action {
    background: #00979e;
    color: #fff;
    width: 100%;
    border-radius: inherit;
}

/* .col.mt-5 {
    margin-left: -25px;
}

@media screen and (max-width:991px) {
    .col.mt-5 {
        width: 80%;
        margin-left: 5% !important;
    }
} */

.col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px;
    position: relative;
    bottom: 20px;
}

@media screen and (max-width:991px) {
    .col-box {
        display: flex;
        margin: auto;
    }
}

.btn-text {
    text-align: center;
    margin: 15px;
    padding: 15px;
    color: #00979e;
}

@media screen and (max-width:991px) {
    .btn-text {
        text-align: center;
        margin: 20px;
        padding: 20px;
        color: #00979e;
    }
}

.col-4.mt-3 {
    width: 20%;
    background-color: white;
}

@media screen and (max-width:992px) {
    .col-4.mt-3 {
        width: 25%;
        background-color: white;
    }
}

button.btn.btn-blue-color.text-white {
    width: 100%;
    font-size: large;
    font-weight: bold;
}



.col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px;
    position: relative;
    bottom: 20px;
}

.col-opt {
    width: auto
}

.patientgroupCard.mt-3 {
    border: 2px solid #00979e;
    width: 100%;
    /* margin-bottom: 30px; */
}


@media screen and (max-width:991px) {
    .col-box {
        display: flex;
        margin: auto;
    }
}

.btn-text {
    text-align: center;
    margin: 10px;
    padding: 20px;
    color: #00979e;
}

@media screen and (max-width:991px) {
    .btn-text {
        text-align: center;
        margin: 20px;
        padding: 20px;
        color: #00979e;
    }
}



button.btn.btn-blue-color.text-white {
    width: 100%;
    font-size: large;
    font-weight: bold;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

:after {
    content: "";
}

/* nav.three-nav {
    float: right;
    position: relative;
    top: 52px;
    left: 70px;
    background: transparent;
} */
nav.three-nav {
    /* float: right; */
    position: fixed;
    right: 0;
    background: transparent;
}

nav.three-nav:hover {
    transform: perspective(400px) rotateY(1deg);
}

nav.three-nav ul.three-nav {
    text-align: center;
    /* margin-left: -72px; */
    /* margin-top: 25px; */
    margin-top: 30px;
}

@media screen and (max-width:992px) {
    nav.three-nav ul.three-nav {
        text-align: center;
        /* margin-left: -72px; */
        margin-top: 0px
            /* margin-top: 77px; */
    }
}

nav.three-nav ul.three-nav li.three-nav {
    position: relative;
    width: 71px;
    cursor: pointer;
    background: cadetblue;
    text-transform: uppercase;
    transition: all .4s ease-out;
    list-style: none;
}

nav.three-nav ul.three-nav li:after {
    position: absolute;
    background: white;
    color: crimson;
    top: 0;
    /* right: 70px; */
    left: 0px;
    width: 70px;
    height: 100%;
    opacity: .5;
    transform: perspective(400px) rotateY(90deg);
    transform-origin: 0 100%;
    transition: all .4s ease-out;
}

nav.three-nav ul.three-nav li:nth-child(1):after {
    /* content: "dcba"; */
    line-height: 88px;
}

nav.three-nav ul.three-nav li:nth-child(2):after {
    /* content: "get"; */
    line-height: 88px;
}

nav.three-nav ul.three-nav li:nth-child(3):after {
    /* content: "offer"; */
    line-height: 85px;
}

nav.three-nav ul.three-nav li:nth-child(4):after {
    /* content: "access"; */
    line-height: 70px;
}

nav.three-nav ul.three-nav li:nth-child(5):after {
    /* content: "post"; */
    line-height: 85px;
}

nav.three-nav ul.three-nav li:hover {
    /* transform: translateX(0px); */
    /* transform: translateX(70px); */
    /* transform: rotateY(-360deg); */
    transform: translateX(0px);
    background-color: #fff;
}

nav.three-nav ul.three-nav li:hover:after {
    opacity: 1;
    /* transform: perspective(400px) rotateY(0deg) scale(1); */
    /* transform: perspective(400px) rotateY(180deg) scale(1); */
    transform: perspective(400px) rotateY(90deg) scale(1);
}


nav.three-nav ul.three-nav li>div {
    display: inline-block;
    padding: 25px 0;
    background: transparent;
}

nav.three-nav ul.three-nav li.three-nav div {
    position: relative;
}


li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu {
    list-style: none;
    position: relative;
    left: 35px;
    /* right: 35px; */
    /* top: 200px; */
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0 solid !important;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.eyes-text p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-text-bg {
    font-size: 45px;
    color: #fff;
}

.row.box-items-space {
    margin-top: 40px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 25% 25% 25%;
    justify-content: center;
}


.col-opt-box {
    width: 100%;
    display: grid;
    justify-content: center;
    cursor:pointer;
}

.patientgroupCard.mt-3.color-bg {
    border: 2px solid #00979e;
    width: 235px;
    /* margin-bottom: 30px; */
    background-color: #00979e;
}

.col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px;
    position: relative;
    bottom: 20px;
}

@media screen and (max-width:991px) {
    .col-box {
        display: flex;
        margin: auto;
    }
}

.btn-text-bg {
    text-align: center;
    padding: 15px;
    color: #fff;
    background-color: #00979e;
}

/* @media screen and (max-width:991px) {
    .btn-text-bg {
        text-align: center;
        margin: 20px;
        padding: 20px;
        color: #fff;
        background-color: #00979e;
    }
} */
@media screen and (max-width:1200px) {
    .row.box-items-space {
        grid-template-columns: 30% 30% 30%;
    }

}
button.btn.btn-action-background {
    background: #fff;
    color: #00979e;
    width: 100%;
    border-radius: inherit;
    border: 2px solid #00979e;
}

@media screen and (max-width:992px) {
    .row.box-items-space {
        grid-gap: 70px;
    }

    .patientgroupCard.mt-3.color-bg {
        width: 210px;
    }

    select.form-control.JoinPatient {
        width: 300px;
        font-size: 18px;
    }
}

@media screen and (max-width:991px) {
    .navbarmobileFix {
        margin-bottom: 7rem;
    }

    .banner-text.img {
        margin-top: 0rem;
        padding: 30px 0px 40px 0px
    }

    .joinPatientText h3 {
        font-size: 22px;
    }

    .joinPatientTextHeading h1 {
        font-size: 33px;
    }

    .selectPatientHeading h2 {
        font-size: 28px;
    }

    .selectPatientHeading {
        margin-top: 2rem;
    }

}

@media screen and (max-width:768px) {
    select.form-control.JoinPatient {
        width: 300px;
        font-size: 16px;
    }

    .row.box-items-space {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
}

@media screen and (max-width: 576px) {
    .joinPatientText h3 {
        font-size: 17px;
    }

    .joinPatientTextHeading h1 {
        font-size: 25px;
    }

    .selectPatientHeading h2 {
        font-size: 21px;
    }

    .selectPatientHeading {
        margin-top: 1.5rem;
    }

}

@media screen and (max-width:550px) {
    .row.box-items-space {
        margin-top: 10px;
    }

    .banner-text.img {
        padding: 10px 0px 24px 0px;
    }

    select.form-control.JoinPatient {
        width: 200px;
        font-size: 14px;
    }
}

@media screen and (max-width:470px) {
    .row.box-items-space {
        grid-gap: 1%
    }

    .patientgroupCard.mt-3.color-bg {
        width: 133px;
    }

    .box-text-bg {
        font-size: 26px;
    }

    .eyes-text p {
        font-size: 16px;
    }

    .btn-text-bg {
        padding: 4px;
    }
}
</style>